import React, { Component } from 'react'

class SlideShow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slideIndex: 0
        }

        this.slides = React.createRef();

        this.showSlides = this.showSlides.bind(this)
    }

    componentDidMount() {
        this.showSlides();
    }

    showSlides() {
        var i;
        if (this.slides.current) {
            var slides = this.slides.current.children;

            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }

            this.state.slideIndex++;

            if (this.state.slideIndex > slides.length) {this.state.slideIndex = 1}

            slides[this.state.slideIndex-1].style.display = "block";
            this.setState({
                slideIndex: this.state.slideIndex
            }, () => {
                setTimeout(this.showSlides, 3000);
            })
        }
        

    }
    
    
    render() {

        return (
            <div ref={this.slides} className="slideshow-container">
                {this.props.img.map((item, index) => 
                    <div key={index} className="mySlides fade" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/image/" + item})`}}>
                        <div className="cover_text">NOW OPEN</div>
                        <div className="cover_sub_text">豆日子 豆花甜品店</div>
                        <a href="https://order.doday.com.tw" className="more_btn cover_btn">立刻訂購</a>
                    </div>)}
            </div>
        );
    }
}

export default SlideShow;