import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.js"
import NavBar from "../components/NavBar.js"

import '../css/main.css';
import '../css/alert.css';
// import { Confirm } from '../alert_utils.js'



class PleaseWaitPage extends Component {

  render() {
    return (
        <div id="body"> 
          <NavBar />

          	<section> 
		      <article>
		        <div className="Wait_introduction">
		          <h1> 功能開發中，敬請期待！</h1>
		        </div>
		      </article>
		    </section>

          <Footer />
        </div>
  
    );}
}

export default PleaseWaitPage;
