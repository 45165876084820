import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import SlideShow from "../components/SlideShow.js"
import Footer from "../components/Footer.js"
import NavBar from "../components/NavBar.js"

import '../css/main.css';
import '../css/alert.css';
import { getDataFromServer, dict2urlEncode } from '../http_utils.js'
// import { Confirm } from '../alert_utils.js'



class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slide_img: ["new_cover_3.png", "new_cover_2_2.png", "cover3.jpg"],
      mobile_slide_img: ["cover_mobile_1.jpg", "cover_mobile_2.jpg", "cover_mobile_3.jpg"],
      slide_img_num: 3,
      news_list: []
    };
  }

  componentDidMount() {
    var request_data = {
      "service":"menu",
      "operation":"latest_news"
    }

    var request_str = dict2urlEncode(request_data)
    getDataFromServer(process.env.REACT_APP_SERVER_URL + '?' + request_str).then(result => {  

      this.setState({
        news_list: JSON.parse(result)
      })
      

    })
  }

  

  render() {
    return (
        <div id="body"> 
          <NavBar />
          <section>
              <article>
                  <SlideShow img={this.state.slide_img} img_num={this.state.slide_img_num}/>
              </article> 
              <article>
                  <div className="shop_menu">
                      <div className="shop">
                          <h1>店舖資訊</h1>
                          <div className="shop_tn">
                              <img src={process.env.PUBLIC_URL + "/image/shop_cover.png"}/>
                              <div className="middle">
                                  <a href="./AboutUs.html" className="see_more">查看店舖資訊 <i className="fas fa-caret-right"></i></a>
                              </div>
                          </div>
                      </div> 
                      <div className="menu">
                          <h1>豆日子菜單</h1>
                          <div className="menu_tn">
                              <img src={process.env.PUBLIC_URL + "/image/menu_cover.jpg"}/>
                              <div className="middle">
                                  <a href="./Menu.html" className="see_more">查看豆日子菜單 <i className="fas fa-caret-right"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </article>
              <article className="pos_relative">
                  <div className="home_news"></div>
                  <div className="empty_space"></div>
                  <div className="news_container">
                      <div className="news">
                          <h1>最新消息</h1>
                          <div className="news_text_container">
                              {/*<div className="hor_scroll latest_news_holder">

                                  { this.state.news_list.map((news_item, index) => (
                                    <div key={index} className="news_text">
                                      <span>{news_item[0]}</span>
                                      <h2>{decodeURIComponent(news_item[1])}</h2>
                                      <a href={news_item[2]} className="more_btn">查看更多</a>
                                    </div>)) }

                              </div> */}
                          </div>
                      </div>
                  </div>
              </article>        
          </section>

          <Footer />
  
        </div>
  
    );}
}

export default HomePage;
