import React, { Component } from 'react'

class Footer extends Component {
    
    render() {

        return (
            <footer>
                <div className="footer_contact_us">
                    <div className="footer_contact_us_info">
                        <a href="https://www.instagram.com/doday_official/?hl=zh-tw"><img src={process.env.PUBLIC_URL + "/image/instagram.png"}/></a>
                        <h1 className="ig">Instagram</h1>
                        <br/>
                        <a href="https://www.facebook.com/pg/豆日子doday-106167927822106/posts/"><img src={process.env.PUBLIC_URL + "/image/facebook.png"}/></a>
                        <h1 className="fb">Facebook</h1>
                        <h1>營業時間 OPENING HOURS Mon-Sun 11:30- 22:00</h1>
                        <h1>地址：台北市文山區木柵路二段149號一樓</h1>
                        <h1>訂購專線: 02 86617800</h1>
                        <h1>contact@nexuni.com</h1>
                    </div>
                    <div className="footer_contact_us_title" id="without_br"><h2>Copyright reserved by 豆日子 豆花甜品店</h2></div>
                </div>
            </footer>
        );
    }
}

export default Footer;