import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.js"
import NavBar from "../components/NavBar.js"

import '../css/main.css';
import '../css/alert.css';



class AboutUsPage extends Component {

  render() {
    return (
        <div id="body"> 
          <NavBar />

          <section>
              <article>
                  <div className="about_doday">
                      <img className="about_doday_img" src={process.env.PUBLIC_URL + "/image/about_doday.png"}/>
                      <img className="about_doday_img_mobile"src={process.env.PUBLIC_URL + "/image/about_doday_mobile_2.jpg"}/>
                      <div className="about_intro_container">
                      </div>
                      <div className="about_intro">
                          <h1>關於Doday豆日子</h1>
                          <p>DoDay，是一家什麼樣的甜品店？
                              早上的豆日子充斥著活力與希望，也許你會遇見在市場買菜的阿姨、偷偷翹課的大學生，或是對這裡感到好奇的日本遊客。這時來碗冰涼的豆花，消解暑氣的同時也能帶給味蕾單純的愉悅。
                              晚上的豆日子，則搖身一變成為夜晚中溫暖的存在。不管是辛勤加班的上班族，還是挑燈夜戰的學生們，我們都會獻上一碗溫熱的豆花，讓他們重新蓄滿面對明天的動力。
                              每個人都懷有自己的人生際遇，而每天只需要一碗豆花的時間，你可以去傾聽屬於他們和豆日子的故事。
                          </p>
                      </div>
                  </div>
              </article>
              <img className="girl" src={process.env.PUBLIC_URL + "/image/girl.png"}/>
              <article className="about2_container">
                  <div className="about_doday2"></div>
                  <img className="about_doday2_img" src={process.env.PUBLIC_URL + "/image/about_doday2.png"}/>
                  <img className="about_doday2_img_mobile" src={process.env.PUBLIC_URL + "/image/about_doday2_mobile.jpg"}/>
                  <div className="about_intro2_container"></div>
                  <div className="about_intro2">
                      <h1>國際級豆花甜品店</h1>
                      <p><strong>一甲子傳統老店技術 + 科技創新團隊</strong><br/>豆日子，期許將台灣傳統美味的豆花等甜品，推向全世界。年輕有活力的經營團隊，用更寬大的國際視角來經營和規劃；在設計上找來國際級設計獲奬的團隊，而在豆花製作上則與一甲子傳統老店技術合作，結合自動化的技術，堅持健康安心的理念，致力於打造一間傳統與科技結合的美味甜品店。
                              傳統美食，讓它奔向世界吧！這是一間明亮有活力的國際級豆花甜品店，也是一間可以在每一天帶給顧客溫暖、充滿你我故事的店。
                      </p>
                  </div>
              </article>
          </section>

          <Footer />
  
        </div>
  
    );}
}

export default AboutUsPage;
