import HomePage from './containers/HomePage'
import AboutUsPage from './containers/AboutUsPage'
import ContactPage from './containers/ContactPage'
import PleaseWaitPage from './containers/PleaseWaitPage'
import MenuPage from './containers/MenuPage'

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
// import { FullScreen, useFullScreenHandle } from "react-full-screen"


function App() {

	const options = {
		position: 'top center',
		timeout: 3000,
		offset: '15px',
		transition: 'fade',
		containerStyle: {
		  zIndex: 10000000
		}
		
	}

	// function toggleFullScreen() {
	// 	var doc = window.document;
	// 	var docEl = doc.documentElement;

	// 	var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
	// 	var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

	// 	if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
	// 		requestFullScreen.call(docEl);
	// 	}
	// 	else {
	// 		cancelFullScreen.call(doc);
	// 	}
	// }

	window.alert = function(name){
        var iframe = document.createElement("IFRAME");
        iframe.setAttribute("src", 'data:text/plain,');
        iframe.style.display="none";
        document.documentElement.appendChild(iframe);
        window.frames[0].window.alert(name);
        iframe.parentNode.removeChild(iframe);
    };


	window.confirm = function (message) {
        var iframe = document.createElement("IFRAME");
        iframe.style.display = "none";
        iframe.setAttribute("src", 'data:text/plain,');
        document.documentElement.appendChild(iframe);
        var alertFrame = window.frames[0];
        var result = alertFrame.window.confirm(message);
        iframe.parentNode.removeChild(iframe);
        return result;
    };
  
	
	return (

	<AlertProvider template={AlertTemplate} {...options}>
		<div className="App">
		    <Switch>
		    	<Route exact path="/" component={HomePage}/>
				<Route path="/AboutUsPage" component={AboutUsPage}/>
				<Route path="/ContactPage" component={ContactPage}/>
				<Route path="/PleaseWaitPage" component={PleaseWaitPage}/>
				<Route path="/MenuPage" component={MenuPage}/>

		    </Switch>
		</div>
		  
	</AlertProvider>

	);
}

export default App;
