import React, { Component } from 'react'

class SlideShow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nav_bar_style: {
                height: "0%"
            }
        }

        this.openNav = this.openNav.bind(this)
        this.closeNav = this.closeNav.bind(this)
    }

    openNav() {
        if (window.matchMedia("(min-width: 1024px)").matches) {
            this.setState({
                nav_bar_style: {
                    height: "60%"
                }
            })
        }
        else {
            this.setState({
                nav_bar_style: {
                    height: "100%"
                }
            })
        }
    }

    closeNav() {
        this.setState({
            nav_bar_style: {
                height: "0%"
            }
        })
    }
    
    
    render() {

        return (
                <header>
                    <div className="nav_bar">
                        <a href="/"><img src={process.env.PUBLIC_URL + "/image/doday_logo_copy.png"} className="logo"/></a>
                        <a href="#" className="icon" onClick={this.openNav}>&#9776;</a>
                    </div>
                    <div aria-label="mobile menu" style={this.state.nav_bar_style} className="mobile_nav">
                        <a href="#" className="closebtn" onClick={this.closeNav}>&times;</a>
                        <div className="mobile_nav_list">
                            <a href="/">主頁</a>
                            <a href="/AboutUsPage">關於我們</a>
                            <a href="/MenuPage">菜單</a>
                            <a href="/ContactPage">聯絡我們</a>
                            <a href="https://order.doday.com.tw">線上點餐</a>
                        </div>
                    </div>
                </header>
        );
    }
}

export default SlideShow;