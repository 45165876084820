import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.js"
import NavBar from "../components/NavBar.js"

import '../css/main.css';
import '../css/alert.css';
import { getDataFromServer, dict2urlEncode } from '../http_utils.js'



class MenuPage extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	cat_list: [],
	    	menu_dict: {}
	    };

	}

	componentDidMount() {
		var request_data = {
			"service":"menu",
			"operation":"get_doday_menu",
			"store_id":"DDA",
			"menu_name":"全日菜單"
		}

	    var request_str = dict2urlEncode(request_data)
	    getDataFromServer(process.env.REACT_APP_SERVER_URL + '?' + request_str).then(result => {  

	    	if (result["indicator"]) {
	    		// console.log(result)
	    		var temp_dict = {}
	    		var cat_key = ""
	    		var item_key = ""
	    		for (var i = 0; i < Object.keys(result["message"]["category"]).length; i++) {
	    			cat_key = Object.keys(result["message"]["category"])[i]
	    			temp_dict[cat_key] = []

	    			for (var j = 0; j < Object.keys(result["message"]["category"][cat_key]).length; j++) {
	    				item_key = Object.keys(result["message"]["category"][cat_key])[j]
	    				temp_dict[cat_key].push({"name": item_key, "more_info": result["message"]["category"][cat_key][item_key]})
	    			}

	    		}

	        	this.setState({
	        		cat_list: Object.keys(result["message"]["category"]),
	        		menu_dict: temp_dict
	        	})

	    	}

	    })
	}

  	render() {
	    return (
	        <div id="body"> 
	          <NavBar />
	          	<div className="menu_category">
			      <ul>
			      	{this.state.cat_list.map((cat_name, index) => <li key={index} className="menu_bar1"><a href={"#" + cat_name}>{cat_name}</a></li>)}
			      </ul>
			    </div>
			    {this.state.cat_list.map((cat_name, index) => (<section key={index} id={cat_name} className="menu_cat1">
		    	{this.state.menu_dict[cat_name].map((item, i) => <article key={i}><div className={ i%2 == 0 ? "menu_row douhua1" : "menu_row douhua2"}>
				<img src={item["more_info"]["img"]} /><div className="menu_intro"><h1>{"【" + cat_name + "】 " + item["name"] + " " + item["more_info"]["price"]}</h1><p>{item["more_info"]["desc"]}</p></div></div></article>)}
				</section>))}
			    
	          <Footer />
	        </div>
	  
	    );}
}

export default MenuPage;
