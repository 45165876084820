import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.js"
import NavBar from "../components/NavBar.js"

import '../css/main.css';
import '../css/alert.css';
// import { Confirm } from '../alert_utils.js'



class ContactPage extends Component {

  render() {
    return (
        <div id="body"> 
          <NavBar />
          <Footer />
        </div>
  
    );}
}

export default ContactPage;
